import React, {createContext} from 'react';
import { firestore } from '../app.js';
import { getHighestValueOfObjectKeyInArray } from '../utils';
export const DocumentsContext = createContext();

export const documentSchema = {
  image: {type: 'image', placeholder: 'bild'},
  title: {type: 'string', placeholder: 'titel'},
  description: {type: 'string', placeholder: 'beskrivning'},
  measurements: {type: 'string', placeholder: 'mått'},
  created: {type: 'date', placeholder: Math.round(new Date().getTime() / 1000)}
};

export default class DocumentsContextProvider extends React.Component {

  state = {
    documents: [],
    editingDocumentId: null
  }

  getDocumentsForPage = async (pageName) => {
    return await firestore.collection('pages').doc(pageName).collection('sections')
      .orderBy('created', 'desc')
      .limit(99)
      .get()
      .then(querySnapshot => {
        var newDocuments = [];
        querySnapshot.forEach(document =>
          newDocuments.push(Object.assign({}, document.data(), {id: document.id}))
        );

        let documents = this.state.documents;
        documents[pageName] = newDocuments;
        this.setState({
          documents: documents
        });
      });
  }

  createDocumentForPage = (pageName) => {
    let schema = documentSchema;
    let newItem = {};
    for (var key in schema) {
      newItem[key] = schema[key].placeholder;
    }
    newItem.position = getHighestValueOfObjectKeyInArray(this.state.documents[pageName], 'position') + 1;
    firestore.collection('pages').doc(pageName).collection('sections').add(Object.assign(newItem)).then((doc)=>{
      this.setEditingDocument(doc.id);
      this.getDocumentsForPage(pageName);
    });
  }

  saveDocumentForPage = (pageName, item, itemID) => {
    firestore.collection('pages').doc(pageName).collection('sections').doc(itemID).update(Object.assign(item)).then(()=>this.getDocumentsForPage(pageName));
    this.setEditingDocument(null);
  }

  deleteDocumentFromPage = (pageName, itemID) => {
    firestore.collection('pages').doc(pageName).collection('sections').doc(itemID).delete();
    //TODO: better way? don't do a get here
    this.getDocumentsForPage(pageName);
    this.setEditingDocument(null);
  }

  updateDocumentPosition = (item, pageName, position) => {
    const updatedItem = {id: item.id, position: position};
    firestore.collection('pages').doc(pageName).collection('sections').doc(item.id).update(Object.assign(updatedItem)).then(()=>this.getDocumentsForPage(pageName));
  }
  setEditingDocument = (id) => {
    this.setState({
      editingDocumentId: id || null
    });
  }


  render = () => (
    <DocumentsContext.Provider value={{
      documents: this.state.documents,
      getDocumentsForPage: this.getDocumentsForPage,
      createDocumentForPage: this.createDocumentForPage,
      saveDocumentForPage: this.saveDocumentForPage,
      deleteDocumentFromPage: this.deleteDocumentFromPage,
      updateDocumentPosition: this.updateDocumentPosition,
      setEditingDocument: this.setEditingDocument,
      editingDocumentId: this.state.editingDocumentId
    }}>
      {this.props.children}
    </DocumentsContext.Provider>
  )
}
