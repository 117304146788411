import React from "react";
import Modal from "./Modal";

export default class ImageComponent extends React.Component {
  constructor() {
    super();
    this.state = {
      showModal: false,
      imageHasLoaded: false,
    };
  }

  toggleModal() {
    this.setState({
      showModal: !this.state.showModal,
    });
  }

  handleImageLoaded() {
    this.setState({ imageHasLoaded: true });
  }

  getResizedUrls = (imageUrl) => {
    if (imageUrl) {
      if (imageUrl.indexOf('images%2F') !== -1) {
        let preUrl = imageUrl.split('images%2F')[0];
        let fileName = imageUrl.split('images%2F')[1]
        if (preUrl.indexOf('.') !== -1) {
          let fileNameWithoutExtension = fileName.split('.')[0];
          return {
            'bigImage': imageUrl.replace('images%2F', 'images%2Fresized%2F').replace(fileNameWithoutExtension, fileNameWithoutExtension+'_800x800'),
            'smallImage': imageUrl.replace('images%2F', 'images%2Fresized%2F').replace(fileNameWithoutExtension, fileNameWithoutExtension+'_128x128')
          };
        }
      }
      return {
        'bigImage': imageUrl,
        'smallImage': imageUrl
      };
    }
  }

  render() {
    const { image, isUploading } = this.props;
    const { showModal, imageHasLoaded } = this.state;
    const { bigImage, smallImage } = this.getResizedUrls(image);
    
    //'http://via.placeholder.com/1280x720'
    //https://firebasestorage.googleapis.com/v0/b/konstfolio-inez.appspot.com/o/images%2Fresized%2F426-TESTIMAGE1_800x800.jpeg?alt=media&token=68d63aa3-d462-4ba5-b898-d9778e9a94d0
    return (
      <div
        style={{
          cursor: "pointer",
          position: "relative",
          marginBottom: "8px",
          maxWidth: this.props.maxWidth ? this.props.maxWidth : "100%",
        }}
      >
        {image !== "bild" && image ? (
          <img
            style={{ maxWidth: "100%" }}
            onLoad={this.handleImageLoaded.bind(this)}
            src={bigImage}
            onClick={() => this.toggleModal()}
          />
        ) : null}

        {isUploading ? (
          <div className="uploadingBackground">
            <h2>Laddar upp ... vänta</h2>
          </div>
        ) : null}
        {!imageHasLoaded ? (
          <div className="animatedBackground imagePlaceholder">
            <div className="sun"></div>
            <div className="smallMountain"></div>
            <div className="bigMountain"></div>
          </div>
        ) : null}
        <Modal
          show={showModal}
          transitionSpeed={1}
          closeOnClickAnywhere
          onClose={() => this.toggleModal()}
        >
          <img
            onLoad={this.handleImageLoaded.bind(this)}
            src={image ? image : "http://via.placeholder.com/1000x1000"}
            style={{
              maxWidth: "100%",
            }}
          />
        </Modal>
      </div>
    );
  }
}
