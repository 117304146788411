import React from 'react';
import EditingToggle from '../components/EditingToggle';
import SettingsLink from '../components/SettingsLink';
import LogoutButton from '../components/LogoutButton';
import { UserContext } from '../contexts/UserContext';

export default class AdminBar extends React.Component {
  static contextType = UserContext;

  state = {
    loading: true
  }

  componentDidMount = async () => {
    this.setState({
      loading: false
    });
  }

  render() {
    return (
      <UserContext.Consumer>
        {context => context.user ? (
      <div style={{zIndex: 999, position: 'fixed', top: 0, left: 0, right: 0, backgroundColor: '#3C5A99', display:'flex', flexDirection:'row', justifyContent: 'flex-end'}}>
        <EditingToggle />
        <SettingsLink />
        <LogoutButton />
      </div>) : null}
      </UserContext.Consumer>
    );
  }
}
