import React, {createContext} from 'react';


export const EditingContext = createContext();

export default class EditingContextProvider extends React.Component {

  state = {
    editing: this.props.editing
  };

  toggleEditing = (boolean, cb) => {
    if (typeof boolean !== 'boolean') {
      this.setState({
        editing: !this.state.editing
      });
      if (cb) cb(!this.state.editing);
    } else {
      this.setState({
        editing: boolean
      });
      if (cb) cb(boolean);
    }
  }

  render = () => (
    <EditingContext.Provider value={{...this.state, toggleEditing: this.toggleEditing}}>
      {this.props.children}
    </EditingContext.Provider>
  )
}
