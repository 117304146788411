

export function ArrayWhereKeyHasProp(array, key, prop) {
  return array.find(function(item) {
    return item[key] === prop;
  });
}

export function SortAlphabetically(array) {
  return array.sort(function(a, b){
    if (!a.name | b.name) {return;}
    if(a.name.toLowerCase() < b.name.toLowerCase()) return -1;
    if(a.name.toLowerCase() > b.name.toLowerCase()) return 1;
    return 0;
  });
}

export function isURL(str) {
  var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
  '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
  '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
  '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
  '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
  '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
  return pattern.test(str);
}

export function getHighestValueOfObjectKeyInArray(array, key) {
  if(array && array.length) {
    const number = Math.max.apply(Math, array.filter(o => typeof o[key] === 'number').map((o) => o[key]));
    return isFinite(number) ? number : -1;
  } else {
    return -1;
  }
}
