module.exports={
  "firebaseSettings": {
    "projectName": "konstfolio-inez",
    "config": {
      "apiKey": "AIzaSyAw0Ju9lUJ4Wn2dYVljg63iRxqG_h4VECU",
      "authDomain": "konstfolio-inez.firebaseapp.com",
      "databaseURL": "https://konstfolio-inez.firebaseio.com",
      "projectId": "konstfolio-inez",
      "storageBucket": "konstfolio-inez.appspot.com",
      "messagingSenderId": "742443490917",
      "appId": "1:742443490917:web:3e797740046909686ff08c",
      "measurementId": "G-BR2ZWCVM1L"
    }
  }
}
