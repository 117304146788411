import React, {createContext} from 'react';


export const UserContext = createContext();

export default class UserContextProvider extends React.Component {
  render = () => (
    <UserContext.Provider value={{user: this.props.user}}>
      {this.props.children}
    </UserContext.Provider>
  )
}
