import * as React from 'react';


export default class Tabs extends React.Component {
  state = {
      active: 0
    }

  select = (i) => {
    let _this = this;
    return function() {
      _this.setState({
        active: i
      });
    };
  }

  renderTabs = () => {
    return React.Children.map(this.props.children, (item, i) => {
      if (i%2 === 0) {
        let active = this.state.active === i ? 'active' : '';
        return (
          <a
            onClick={this.select(i)}
            style={{
              flex:1,
              height: '40px',
              padding: '12px',
              cursor: 'pointer',
              borderBottomStyle: 'solid',
              borderBottomWidth: active ? '4px' : 0,
              borderColor: active ? '#4285f4' : '',
              fontWeight : active ? 'bold' : ''
          }}>
            {item}
          </a>
        );
      }
    });
  }

  renderContent() {
    return React.Children.map(this.props.children, (item, i) => {
      if (i-1 === this.state.active) {
        return (
          <div style={{marginTop: '40px'}}>
            {item}
          </div>
        );
      } else {
        return;
      }
    });
  }

  render() {
    return (
      <div>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          textAlign: 'center'
        }}>
          {this.renderTabs()}
        </div>
        {this.renderContent()}
      </div>
    );
  }
}
