import React, {createContext} from 'react';
import { firestore } from '../app.js';

export const SettingsContext = createContext();

export default class SettingsContextProvider extends React.Component {
  state = {
    settings: {
      title: '',
      keywords: '',
      description: '',
      favicton: ''
    }
  };

  getSettings = async () => {
    return await firestore.collection('settings')
      .doc('settings')
      .get()
      .then(doc => {
        if (doc.exists) {
          const settings = doc.data();
          this.setState({settings});
        } else {
          return;
        }
      });
  }

  saveSettings = async (settings) => {
    return await firestore.collection('settings').doc('settings').set({
      ...settings,
      changed: Date.now()
    });
  }

  render = () => (
    <SettingsContext.Provider value={{
      settings: this.state.settings,
      getSettings: this.getSettings,
      saveSettings: this.saveSettings
    }}>
      {this.props.children}
    </SettingsContext.Provider>
  )
}
