import React from 'react';
import {NavLink} from 'react-router-dom';
import { PagesContext } from '../contexts/PagesContext';
import { EditingContext } from '../contexts/EditingContext';
import PageEditor from '../components/PageEditor';

export default class Navigation extends React.Component {
  static contextType = PagesContext;

  state = {
    loading: true
  }

  componentDidMount = async () => {
    await this.context.getPages();
    this.setState({
      loading: false
    });
  }

  render() {
    return this.state.loading ? null : (
      <EditingContext.Consumer>
        {(context) => context.editing ? (
          <PageEditor {...this.props}/>
        ) : (
          <div className="navigation">
            {this.context.pages && this.context.pages.length ? this.context.pages.filter(page => page.id !== 'start').sort((a, b) => a.position - b.position).map((page) => (
              <NavLink key={page.id} to={'/'+page.id}>
                 {page.id}
               </NavLink>
            )) : null}
          </div>
        )}
        </EditingContext.Consumer>
      );
  }
}
