import React from 'react';
import UserContextProvider from './UserContext';
import PagesContextProvider from './PagesContext';
import EditingContextProvider from './EditingContext';
import SettingsContextProvider from './SettingsContext';

const compose = (contexts, children) =>
  contexts.reduce((acc, [ContextProvider, value]) => {
    let props = [];
    if (value) props = value;
    return <ContextProvider {...props}>{acc}</ContextProvider>;
  }, children);

const ContextStore = (props) => {
  //[{Context, initialState}]
  return compose(
    [
      [UserContextProvider, props.user],
      [EditingContextProvider],
      [PagesContextProvider],
      [SettingsContextProvider],
    ],
    props.children
  );

};
export default ContextStore;
