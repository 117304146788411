import * as React from 'react';
import { auth } from '../app.js';
import { UserContext } from '../contexts/UserContext';
import { EditingContext } from '../contexts/EditingContext';

export default class LogoutButton extends React.Component {
  static contextType = EditingContext;

  logout() {
    this.context.toggleEditing(false, () => {
      auth.signOut();
    });
  }

  render() {
    return (
      <UserContext.Consumer>
        {context => context.user ? (
            <div className="global-button" onClick={() => this.logout()}>
              <svg width="32" height="32" viewBox="0 0 32 32">
                <path fill="#fff" d="M10 9.408l2.963 2.592-2.963 2.592v-1.592h-8v-2h8v-1.592zm-2-4.408v4h-8v6h8v4l8-7-8-7zm6-3c-1.787 0-3.46.474-4.911 1.295l.228.2 1.396 1.221c1.004-.456 2.114-.716 3.287-.716 4.411 0 8 3.589 8 8s-3.589 8-8 8c-1.173 0-2.283-.26-3.288-.715l-1.396 1.221-.228.2c1.452.82 3.125 1.294 4.912 1.294 5.522 0 10-4.477 10-10s-4.478-10-10-10z"/>
              </svg>
            </div>
        ) : null}
      </UserContext.Consumer>
    );
  }
}
