import React from 'react';
import LoginForm from '../components/LoginForm';

var style = {
  'position':'relative',
  'padding': '8px'
};

export default class Page extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div style={style}>
          <LoginForm {...this.props}/>
        </div>
      </React.Fragment>
    );
  }
}
