import React, {createContext} from 'react';
import { firestore } from '../app.js';
import { getHighestValueOfObjectKeyInArray } from '../utils';

export const PagesContext = createContext();

export default class PagesContextProvider extends React.Component {
  state = {
    pages: []
  }

  getPages = async () => {
    return await firestore.collection('pages')
     .get()
     .then(querySnapshot => {
       var pages = [];
       querySnapshot.forEach(page => pages.push(page.data()));
       this.setState({
         pages: pages
       });
     });
  }

  createPage = (newPageName) => {
    if(newPageName && newPageName.length > 2) {
      var randomId = Math.random().toString(36).substring(7);
      firestore.collection('pages').doc(newPageName).set({pageName: newPageName, id: newPageName,
      position: getHighestValueOfObjectKeyInArray(this.state.pages, 'position') + 1}).then(
        firestore.collection('pages').doc(newPageName).collection('sections').doc(randomId).set({
          id: randomId
        }).then(this.getPages)
      );
    }
  }

  updatePagePosition = (page, position) => {
    const updatedPage = {id: page.id, position: position};
    firestore.collection('pages').doc(page.id).update(Object.assign(updatedPage)).then(this.getPages);
  }

  deletePage = (id) => {
    firestore.collection('pages').doc(id).delete().then(this.getPages);
  }

  render = () => (
    <PagesContext.Provider value={{
      pages: this.state.pages,
      getPages: this.getPages,
      createPage: this.createPage,
      deletePage: this.deletePage,
      updatePagePosition: this.updatePagePosition
    }}>
      {this.props.children}
    </PagesContext.Provider>
  )
}
