import * as React from 'react';
import { auth } from '../app.js';
import { EditingContext } from '../contexts/EditingContext';
import { UserContext } from '../contexts/UserContext';

export default class EditingToggle extends React.Component {
  static contextType = EditingContext;

  logout() {
    this.context.toggleEditing(false, () => {
      auth.signOut();
    });
  }

  render() {
    const { editing, toggleEditing } = this.context;
    return (
      <UserContext.Consumer>
        {context => context.user ? (
          <div className="editing-toggle-button">
            <div className="global-button" onClick={() => toggleEditing(null, () => {})}>
              { editing ? (
                <svg stroke="#fff" fill="#fff" style={{'verticalAlign':'middle'}} width="32" height="32" viewBox="0 0 512 512">
                  <path d="M141.578 298.957c0-45.65 26.778-84.941 65.465-103.301-123.003 22.303-203.417 112.681-203.417 112.681 76.718 63.365 149.197 91.197 214.016 98.334-44.298-15.79-76.063-57.989-76.063-107.714z"/>
                  <path d="M508.375 299.223c-74.158-66.12-144.875-95.867-208.702-104.079 41.543 17.162 70.748 57.989 70.748 105.728 0 48.313-30.003 89.508-72.315 106.332 127.693-18.483 210.268-107.981 210.268-107.981z"/>
                  <path d="M314.358 240.118c0.236 1.372 0.44 2.744 0.44 4.157 0 14.981-12.144 27.095-27.115 27.095-14.94 0-27.095-12.114-27.095-27.095 0-11.182 6.789-20.746 16.425-24.914-6.717-1.72-13.762-2.744-21.043-2.744-46.663 0-84.5 37.847-84.5 84.531 0 46.663 37.837 84.531 84.5 84.531 46.725 0 84.541-37.868 84.541-84.531 0.010-24.023-10.066-45.639-26.153-61.031z"/>
                  <path d="M354.241 188.303l26.682-54.505 27.602 13.512-26.682 54.505-27.602-13.512z" />
                  <path d="M127.658 200.833l-23.756-51.3 27.876-12.909 23.756 51.3-27.876 12.909z" />
                  <path d="M231.391 177.43l0.85-72.635 30.717 0.359-0.85 72.635-30.717-0.359z" />
                </svg>
              ) : (
                <svg style={{'verticalAlign':'middle'}} width="32" height="32" viewBox="0 0 1000 1000">
                  <path stroke="#fff" fill="#fff" d="M954.1,132.5l-86.6-86.6C843.6,22,812.2,10,780.9,10s-62.7,12-86.6,35.9L168.4,571.8c-23.9,23.9-97.1,116.5-97.1,147.8L10,990l270.4-61.2c0,0,123.9-73.2,147.9-97.1l525.9-525.9C1002,257.9,1002,180.3,954.1,132.5z M91.2,908.8L131,733.1c0.6-2.5,1-5.1,1.2-7.6c0.1-0.4,0.5-0.9,0.6-1.3l138.5,138.5c-4.6,2.8-9.3,5.6-13.9,8.4L91.2,908.8z M385,788.2c-6.9,6.6-30.9,22.8-60.2,41.5L166.1,670.9c16.3-22.3,34.4-44.7,45.5-55.9l396-396l173.2,173.2L385,788.2z M910.8,262.4L824.2,349L651,175.8l86.6-86.6c11.5-11.6,26.9-17.9,43.3-17.9s31.8,6.4,43.3,17.9l86.6,86.6c11.6,11.5,17.9,26.9,17.9,43.3C928.7,235.4,922.4,250.9,910.8,262.4z"/>
                </svg>
              )}
            </div>
          </div>
        ) : null}
      </UserContext.Consumer>
    );
  }
}
