import 'babel-polyfill';
import * as React from 'react';
import ReactDOM from 'react-dom';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/analytics';
import {HashRouter as Router} from 'react-router-dom';
const config = require('../../functions/config.json');

import UI from './containers/UI.js';
import ActivityIndicator from './components/ActivityIndicator.js';
import ContextStore from './contexts/ContextStore.js';


firebase.initializeApp(config.firebaseSettings.config);
export const provider = new firebase.auth.GoogleAuthProvider();
export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const storage = firebase.storage();
export const analytics = firebase.analytics();

class Loader extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      user: false,
      pages: [],
      loading: true
    };
  }

  componentDidMount() {
    auth.onAuthStateChanged((user) => {
      if (user) {
        this.setState({user: user, loading: false});
      } else {
        this.setState({user: false, loading: false});
      }
    });
    this.appIsSetup();
  }

  appIsSetup() {
    firestore.collection('users').get().then(data => {
      if (!data || !data.size) {
        this.props.history.push('/settings');
      }
    });
  }

  render() {
    const {user, loading} = this.state;
    return (
      <Router>
      {loading ? <ActivityIndicator/> : (
        <ContextStore user={{user:user}}>
          <UI/>
        </ContextStore>
      )}
      </Router>
    );
  }
}

ReactDOM.render(
  <Loader/>
, document.querySelector('#ui'));
