import * as React from 'react';
import {Button} from './Button';
import { auth, provider } from '../app.js';
import { UserContext } from '../contexts/UserContext';

export default class LoginForm extends React.Component {
  static contextType = UserContext;

  logout() {
      auth.signOut();
      window.location.reload();
  }

  login() {
    auth.signInWithPopup(provider)
    .then(() => {
      this.props.history.push('/');
    });
  }

  render() {
    return !this.context.user ? <Button text="Logga in" onClick={this.login.bind(this)}/> : <Button text="Logga ut" onClick={this.logout.bind(this)}/>
    ;
  }
}
