import React from 'react';
import SettingsForm from '../components/SettingsForm';
//import PageEditor from '../components/PageEditor';
import Tabs from '../components/Tabs';

var style = {
  'position':'relative',
  'padding': '8px',
  'width': '100%',
  'maxWidth': '600px',
  'minHeight': '100vh'
};

export default class Page extends React.Component {
  render() {
    return (
      <div style={style}>
        <Tabs>
          Inställningar
          <SettingsForm {...this.props}/>
          {/*
          Sidor
          <PageEditor {...this.props}/>
          */}
        </Tabs>
      </div>
    );
  }
}
