import React from 'react';
import {Route} from 'react-router-dom';

import Logo from '../components/Logo.js';
import Modal from '../components/Modal.js';
import Navigation from './Navigation.js';
import AdminBar from './AdminBar.js';

import LoginPage from '../pages/LoginPage.js';
import SettingsPage from '../pages/SettingsPage.js';

import DocumentDisplayer from '../components/DocumentDisplayer';
import DocumentsContextProvider from '../contexts/DocumentsContext';
import { PagesContext } from '../contexts/PagesContext';

import { withRouter } from 'react-router-dom';

const UIComponent = class UI extends React.Component {
  static contextType = PagesContext;

  loginPage = (props) => {
    return <LoginPage {...props}/>;
  }
  setupPage = (props) => {
    return (
      <Modal show transitionSpeed={1} onClose={() => this.props.history.push('/')}>
        <SettingsPage {...props} closeModal={() => this.props.history.push('/')}/>
      </Modal>
    );
  }

  sectionsPage = (props) => {
    var pathName = this.props.location.pathname.substring(1) || 'start';
    if(this.context.pages && this.context.pages.length && this.context.pages.find((page) => page.pathName !== -1 )) {
      return (
        <div
        key={pathName}
        style={{
          'position':'relative',
          'padding': '8px'
        }}>
          <DocumentsContextProvider>
            <DocumentDisplayer pageName={pathName} {...props} />
          </DocumentsContextProvider>
        </div>
      );
    } else {
      return null;
    }
  }

  render() {
    return (
      <React.Fragment>
        <AdminBar/>
        <Logo/>

        <Navigation {...this.props}/>
        <Route exact path="/login" component={this.loginPage} />
        <Route exact path="/settings" component={this.setupPage} />
        <Route component={this.sectionsPage} />

      </React.Fragment>
    );
  }
};

export default withRouter(UIComponent);
