import * as React from 'react';
import {Button} from './Button';
import Input from './Input';
import ImageUploader from './ImageUploader';
import ImageComponent from './ImageComponent';
import { auth, provider, storage } from '../app.js';
import { isURL } from '../utils.js';
const config = require('../../../functions/config.json');
import { UserContext } from '../contexts/UserContext';
import { SettingsContext } from '../contexts/SettingsContext';
import ActivityIndicator from './ActivityIndicator.js';

export default class SettingsForm extends React.Component {
  static contextType = SettingsContext;

  state = {
    loading: true,
    settings: Object.assign(this.context.settings)
  };

  componentDidMount = async () => {
    await this.context.getSettings();
    this.setState({loading: false, settings: this.context.settings});
  }

  logout() {
    this.props.toggleEditing(false, () => {
      auth.signOut();
    });
  }

  login() {
    auth.signInWithPopup(provider)
    .then((data) => {
      if(data && data.user && data.user.email && data.user.email.length) {
        var xmlhttp = new XMLHttpRequest();   // new HttpRequest instance
        var xmlurl = 'https://us-central1-'+(config.firebaseSettings.projectName).toLowerCase()+'.cloudfunctions.net/setup';
        xmlhttp.open('POST', xmlurl, false);
        xmlhttp.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
        xmlhttp.send(JSON.stringify({ 'user': { 'email': data.user.email } }));
        return xmlhttp.responseText;
      }
    });
  }

  changeValue(key, value) {
    let state = this.state;
    state.settings[key] = value;
    this.setState(state);
  }

  uploadImage(event, itemKey, isUploadingFN, uploadedFileFN) {
    const file = event.target.files[0];
    if (file) {
      isUploadingFN(true);
      let that = this;
      let storageRef = storage.ref();
      storageRef.child(`images/${file.name}`).put(file).then(function(snapshot) {
        setTimeout(() => {
          snapshot.ref.getDownloadURL().then(downloadURL => {
            that.changeValue(itemKey, downloadURL);
            isUploadingFN(false);
            uploadedFileFN(downloadURL);
          });
        }, 8000);
      });
    }
  }

  saveSettings = async () => {
    await this.context.saveSettings(this.state.settings);
    this.props.closeModal();
  }

  render() {
    const {loading} = this.state;
    const {title, keywords, description, favicon} = this.state.settings;
    return loading ? <ActivityIndicator/> : (
      <UserContext.Consumer>
        {context => !context.user ? (
          <Button text="Logga in" onClick={this.login.bind(this)}/>
        ) : (
          <React.Fragment>
            <h2>Konstfolio - Settings</h2>
            <small>{context.user.email}</small>
            <br/>
            <br/>
            <h3>Website title</h3>
            <Input
              isTextArea={false}
              value={title}
              placeholder={'JaneDoe\'s'}
              onChange={
                (event) => this.changeValue('title', event.target.value)}
            />
            <h3>SEO</h3>
            <p>Description</p>
            <Input
              isTextArea={true}
              value={description}
              placeholder={'Jane Doe\'s Gallery, Contact: jane.doe@email.com'}
              onChange={
                (event) => this.changeValue('description', event.target.value)}
            />
            <p>Keywords <small>(separated by commas: art, canvas, oil )</small></p>
            <Input
              isTextArea={true}
              value={keywords}
              placeholder={'jane, doe, art, canvas, oil'}
              onChange={
                (event) => this.changeValue('keywords', event.target.value)}
            />
            <h3>Favicon</h3>

            <ImageUploader uploadImage={this.uploadImage.bind(this)} imageUrl={favicon && favicon.length && isURL(favicon) ? favicon : 'http://via.placeholder.com/32x32'} itemKey={'favicon'}/>

            <ImageComponent image={favicon && favicon.length && isURL(favicon) ? favicon : 'http://via.placeholder.com/32x32'} maxWidth={'32px'}/>

            <Button text="Spara" onClick={this.saveSettings.bind(this)}/>
          </React.Fragment>
        )}
      </UserContext.Consumer>);

  }
}
