import React from 'react';

var inputStyle = {
  display: 'inline-block',
  width: '100%',
  outline: 'none',
  boxShadow: 'none',
  border: '2px solid #efefef',
  padding: '8px',
  borderRadius: '2px',
  background: 'transparent',
  position: 'relative',
  font: 'normal medium Minion, serif',

  marginBottom: '8px'
};
export default class Input extends React.Component {

  render() {
    const {isTextArea} = this.props;
    const wrapperStyle= {'flex': '1', 'position':'relative', width: this.props.width ? this.props.width : '100%'};
    return (
      <div 
        style={wrapperStyle}
      >
      {isTextArea ? (
        <textarea 
          style={inputStyle} 
          value={this.props.value} 
          onChange={this.props.onChange} 
          placeholder={this.props.placeholder}
        />
      ) : (
        <input 
          style={inputStyle} 
          value={this.props.value} 
          onChange={this.props.onChange} 
          placeholder={this.props.placeholder}
        />
      )}

      </div>
    );
  }
}