import * as React from 'react';
import {Button} from './Button';
import Modal from './Modal';
import Input from './Input';
import { PagesContext } from '../contexts/PagesContext';
import { UserContext } from '../contexts/UserContext';
import ActivityIndicator from './ActivityIndicator.js';
import {NavLink} from 'react-router-dom';

export default class PageEditor extends React.Component {
  static contextType = PagesContext;
  state = {
    loading: false,
    newPageName: '',
    showAddPageModal: false
  };

  changeValue(key, value) {
    this.setState({[key]: value});
  }

  deletePage = (id) => {
    let pages = this.context.pages;
    const pageToDeletePosition = this.context.pages.find(page => page.id === id).position;
    pages.forEach((page) => {
      if ( page.position > pageToDeletePosition) {
        page.position = page.position - 1;
        this.context.updatePagePosition(page, page.position);
      }
    });
    this.context.deletePage(id);
  }

  createPage = () => {
    const {newPageName} = this.state;
    this.toggleAddPageModal();
    this.context.createPage(newPageName);
    this.context.getPages();
  }

  toggleAddPageModal = () => {
    this.setState({showAddPageModal: !this.state.showAddPageModal});
  }

  moveLeft = (pageId) => {
    let pages = this.context.pages;
    let pageToMove = pages.find(page => page.id === pageId);
    let pageBefore = pages.find(page => page.position === pageToMove.position - 1);
    if (pageBefore && pageBefore.pageName !== 'start') {
      pageToMove.position = pageToMove.position - 1;
      pageBefore.position = pageBefore.position + 1;
      pages.forEach((page) => {
        this.context.updatePagePosition(page, page.position);
      });
    }
  }

  moveRight = (pageId) => {
    let pages = this.context.pages;
    let pageToMove = pages.find(page => page.id === pageId);
    let pageAfter = pages.find(page => page.position === pageToMove.position + 1);
    if (pageAfter && pageAfter.pageName !== 'start') {
      pageToMove.position = pageToMove.position + 1;
      pageAfter.position = pageAfter.position - 1;
      pages.forEach((page) => {
        this.context.updatePagePosition(page, page.position);
      });
    }
  }

  render() {
    const {newPageName, showAddPageModal} = this.state;
    const {pages} = this.context;
    return (
      <UserContext.Consumer>
        {context => context.user ? (
          this.state.loading ? <ActivityIndicator/> : (
            <React.Fragment>
              <Modal show={showAddPageModal} transitionSpeed={1} onClose={this.toggleAddPageModal}>
                <span style={{display: 'flex', flexDirection:'row', justifyContent: 'space-between'}}>
                  <Input
                    isTextArea={false}
                    value={newPageName.toLowerCase()}
                    placeholder={'contact'}
                    onChange={
                      (event) => this.changeValue('newPageName', event.target.value.toLowerCase())}
                  />
                  <Button text="Skapa ny sida" onClick={this.createPage}/>
                </span>
              </Modal>
              <div style={{display: 'flex', flexDirection:'row', justifyContent: 'space-between', paddingTop: 16}}>
                {
                  pages.filter(a => a.pageName !== 'start').sort((a, b) => a.position - b.position).map((page, index) => {
                    return (
                        <div key={index} style={{flex: 1, flexDirection: 'column'}}>
                          <NavLink key={page.id} to={'/'+page.id}>{page.id}</NavLink>
                          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                          {index !== 0 ? (
                            <div style={{flex: 1, color: 'blue', padding: 8, cursor: 'pointer'}} onClick={() => this.moveLeft(page.id)} role="button">
                              {'<'}
                            </div>
                          ) : null}
                          <div style={{flex: 1, color: 'red', padding: 8, cursor: 'pointer'}} onClick={() => this.deletePage(page.id)} role="button">
                            x
                          </div>
                          {pages.filter(a => a.pageName !== 'start').length -1 === index ? null : (
                          <div style={{flex: 1, color: 'blue', padding: 8, cursor: 'pointer'}} onClick={() => this.moveRight(page.id)} role="button">
                            {'>'}
                          </div>
                          )}
                          </div>
                        </div>
                    );
                  })

                }
                <div style={{display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'center'}}>
                  <div style={{flex: 1, color: 'green', padding: 8, cursor: 'pointer'}} onClick={() => this.toggleAddPageModal()} role="button">
                    {'+'}
                  </div>
                </div>
              </div>
            </React.Fragment>
          )
        ) : null}
      </UserContext.Consumer>
    );
  }
}
